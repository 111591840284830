import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slice from "../components/slice"
import { formatDate } from "../utils/data"

const BlogTemplate = ({ data }) => {
  const { title, publish_date, body } = data.prismicBlog.data
  const seoDescription = body
    .filter(slice => slice.slice_type === "richtext")
    .map(slice => slice.primary.richtext.text)
    .join(" ")
    .substring(0, 400)
  return (
    <Layout>
      <SEO title={title.text} description={seoDescription} />
      <div className="max-w-6xl mx-auto px-4 my-8 w-full">
        {publish_date && (
          <p className="text-sm text-center">{formatDate(publish_date)}</p>
        )}
        <h1 className="mt-4 mb-2 text-center">{title.text}</h1>
      </div>

      {body.map((slice, i) => (
        <Slice {...slice} key={i} />
      ))}
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogByUid($uid: String!) {
    prismicBlog(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        publish_date
        body {
          ... on PrismicBlogBodyRichtext {
            primary {
              richtext {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicBlogBodyImage {
            slice_type
            primary {
              image {
                fluid(maxWidth: 1440) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogBodyVimeoEmbed {
            id
            slice_type
            primary {
              video_description {
                html
              }
              video_id
            }
          }
        }
      }
    }
  }
`
